import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CreatePasswordService {
  private createPasswordBaseUrl = environment.baseBackendUrl + '/verification';

  private getAgentCodeByTokenUrl = this.createPasswordBaseUrl;
  private changePasswordUrl = this.createPasswordBaseUrl + '/change-password';
  private sendOtpUrl = this.createPasswordBaseUrl + '/resend-otp';
  private sendEmailurl = this.createPasswordBaseUrl + '/resend-email';
  private sendEmailLinkurl = this.createPasswordBaseUrl + '/resend-email-link';
  private resetPasswordurl = this.createPasswordBaseUrl + '/reset-password';

  constructor(private http: HttpClient) { }

  getAgentCodeByToken(token: string) {
    return this.http.get(this.getAgentCodeByTokenUrl, {
      params: new HttpParams().set('token', token)
    });
  }

  createPasswordByAgentCode(data: any) {
    return this.http.post(this.changePasswordUrl, data);
  }

  sendOtp(data: any) {
    return this.http.post(this.sendOtpUrl, data);
  }

  sendEmail(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.sendEmailurl, data, httpOptions);
  }

  sendEmailLink(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.sendEmailLinkurl, data, httpOptions);
  }

  resetPassword(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.resetPasswordurl, data, httpOptions);
  }
}
