import { CommonModule } from "@angular/common";
import { NgModule } from '@angular/core';
import { CommaToDotPipe } from './comma-to-dot.pipe';
import { EnumToArrayPipe } from "./enum-to-array.pipe";
import { SafeHtmlPipe } from './safe-html.pipe';

@NgModule({
    declarations: [EnumToArrayPipe, CommaToDotPipe, SafeHtmlPipe],
    imports: [CommonModule],
    exports: [EnumToArrayPipe, CommaToDotPipe, SafeHtmlPipe]
})

export class EnumToErrayPipeModule { }