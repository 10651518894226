import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class UserServiceService {

  private userBaseUrl = environment.baseBackendUrl + '/user';

  private getUserUrl = this.userBaseUrl + '/index';
  private getAgentDataUrl = this.userBaseUrl + '/agent';
  private changePasswordUrl = this.userBaseUrl + '/change-password';
  private isUserOnEbaoUrl = this.userBaseUrl + '/is-on-ebao';
  private siiUrl = this.userBaseUrl + '/sii';


  constructor(
    private http: HttpClient
  ) { }

  getUser() {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(this.getUserUrl, httpOptions);
  }

  updateUserRole(userId, roleId) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.userBaseUrl + '/' + userId + '/assign/' + roleId, {}, httpOptions);
  }

  createUser(request) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.userBaseUrl + '/store', request, httpOptions);
  }

  deleteUser(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.userBaseUrl + '/' + id + '/delete', {}, httpOptions);
  }

  updateUser(id, request) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.userBaseUrl + '/' + id + '/update', request, httpOptions);
  }

  findUserById(id) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(this.userBaseUrl + '/' + id + '/find', httpOptions);
  }

  getAgentData(agentCode) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.get(`${this.getAgentDataUrl}/${agentCode}`, httpOptions);
  }

  changePassword(request) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.changePasswordUrl, request, httpOptions);
  }

  isOnEbao(request) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.isUserOnEbaoUrl, request, httpOptions);
  }

  getSii(data: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        Authorization: 'Bearer ' + localStorage.getItem('token')
      })
    };
    return this.http.post(this.siiUrl, data, httpOptions);
  }
}
