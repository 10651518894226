import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BancassuranceService {
  private BANCE_BASE_URL = environment.baseBackendUrl + '/bancassurance';

  private API = {
    IS_PROFILE: this.BANCE_BASE_URL + '/is-profile',
    SII: {
      GET_DATA: this.BANCE_BASE_URL + '/sii/get-data',
      FIND_ONE: this.BANCE_BASE_URL + '/sii/find',
    },
    EPPAJ: {
      GET_DATA: this.BANCE_BASE_URL + '/eppaj/get-data',
      FIND_ONE: this.BANCE_BASE_URL + '/eppaj/find',
    },
    REPORT_LEADS: {
      GET_DATA: this.BANCE_BASE_URL + '/report-leads/get-data',
      FIND_ONE: this.BANCE_BASE_URL + '/report-leads/find',
    }
  }

  private httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('token')
    })
  }

  constructor(private http: HttpClient) { }

  getSIIList() {
    return this.http.get(this.API.SII.GET_DATA, this.httpOptions).pipe(
      catchError((err: any) => {
        return throwError(err)
      })
    );
  }

  getSIIById(id: number) {
    return this.http.get(`${this.API.SII.FIND_ONE}/${id}`, this.httpOptions).pipe(
      catchError((err: any) => {
        return throwError(err)
      })
    );
  }

  getEPPAJList() {
    return this.http.get(this.API.EPPAJ.GET_DATA, this.httpOptions).pipe(
      catchError((err: any) => {
        return throwError(err)
      })
    );
  }

  getEPPAJById(id: number) {
    return this.http.get(`${this.API.EPPAJ.FIND_ONE}/${id}`, this.httpOptions).pipe(
      catchError((err: any) => {
        return throwError(err)
      })
    );
  }

  getIsProfileByAgentCode(agentCode) {
    return this.http.get(`${this.API.IS_PROFILE}/${agentCode}`, this.httpOptions).pipe(
      catchError((err: any) => {
        return throwError(err)
      })
    );
  }

  getReportLeadsList() {
    return this.http.get(this.API.REPORT_LEADS.GET_DATA, this.httpOptions).pipe(
      catchError((err: any) => {
        return throwError(err)
      })
    );
  }

  getReportLeadsById(id: number) {
    return this.http.get(`${this.API.REPORT_LEADS.FIND_ONE}/${id}`, this.httpOptions).pipe(
      catchError((err: any) => {
        return throwError(err)
      })
    );
  }

}
