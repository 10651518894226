export enum Education {
    'SLTP',
    'SLTA',
    'D1',
    'D2',
    'D3',
    'D4',
    'S1',
    'S2',
    'S3'
}
